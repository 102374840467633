import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useCountryCurrency} from "../../../../helpers/useCountryCurrency";

const moment = require("moment");

export const CheckoutSummary = ({checkoutData, appointment = null}) => {
  const {t} = useTranslation();
  const [totalPrice, setTotalPrice] = useState();
  const getRHClubState = useSelector((state) => state.rhClubReducer);
  const countryCurrency = useCountryCurrency();
  let isCouponPreFilled = localStorage.getItem('couponCode');
  // console.log("countryCurrency", countryCurrency);
  const {rh_club} = getRHClubState;
  useEffect(() => {
    // Use parseFloat instead of parseInt to handle decimal values
    let price = parseFloat(checkoutData?.totalPrice) || 0;
    // checkoutData?.page === 5
    //   ? parseFloat(checkoutData?.total_amount)
    //   : parseFloat(checkoutData?.totalPrice) || 0;

    // Use Number() instead of conditional operator to handle non-numeric values
    price +=
      checkoutData?.CodCharges > 0 ? Number(checkoutData?.CodCharges) : 0;
    price += checkoutData?.rushSlot > 0 ? Number(checkoutData.rushSlot) : 0;
    // price -=
    //   Number(checkoutData?.total_discount) > 0 && Number(checkoutData?.page) > 5
    //     ? Number(checkoutData.total_discount)
    //     : 0;
    price +=
      checkoutData?.isBuyMemberShipRadio === true
        ? countryCurrency[0]?.rhPrice
        : 0;
    price +=
      checkoutData?.holidayPrice > 0 ? Number(checkoutData.holidayPrice) : 0;
    price -=
      checkoutData?.voucherDiscount > 0
        ? Number(checkoutData.voucherDiscount)
        : 0;
    price -=
      checkoutData?.rh_discount > 0 ? Number(checkoutData.rh_discount) : 0;

    if (checkoutData?.wallet_amount > 0 && checkoutData?.isWallet) {
      price = checkoutData?.isWallet
        ? Math.max(price - checkoutData?.deducted_amount, 0)
        : price;
    }
    price = Math.max(price, 0)
    setTotalPrice(price);
  }, [checkoutData]);

  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  return (
    <>
      <div className="card cardDetails">
        <div className="card-content ">
          {/* <p>{ t('service_details') }</p>  */}
          <ul>
            <li>
              {t("frequency")}{" "}
              <span>
                {currentLanguage === "en"
                  ? checkoutData?.frequency_name
                  : checkoutData?.frequency_nameArabic}
              </span>
            </li>
            <li>
              {t("duration")}{" "}
              <span>
                {checkoutData?.hours_count} {t("hours")}
              </span>
            </li>
            <li>
              {t("no_of_professionals")}{" "}
              <span>{checkoutData?.professionals_count}</span>
            </li>
            <li>
              {t("material")}{" "}
              <span>
                {checkoutData?.is_cleaning_materials ? t("yes") : t("no")}
              </span>
            </li>
          </ul>
        </div>
        <div className="card-content ">
          <p>{t("date_and_time")}</p>
          <ul>
            <li>
              {t("professional")}{" "}
              <span>
                <div className="is-flex">
                  {checkoutData.professional_full_name
                    ? checkoutData.professional_full_name
                    : ""}
                  {checkoutData.professional_full_name !== "Auto assign" &&
                  checkoutData.professional_full_name !== "" &&
                  checkoutData.professional_profile_image !== "" ? (
                    <img
                      src={checkoutData.professional_profile_image}
                      className="cleaner-image en"
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                </div>
              </span>
            </li>
            <li>
              {t("date")}{" "}
              <span>
                {checkoutData.appointment_date
                  ? moment(checkoutData.appointment_date, [
                      "YYYY-MM-DD"
                    ]).format("dddd, MMMM DD")
                  : ""}
              </span>
            </li>
            <li>
              {t("start_time")}{" "}
              <span>
                {checkoutData.start_time_between
                  ? checkoutData.start_time_between
                  : ""}
              </span>
            </li>
          </ul>
        </div>

        <div className="card-content ">
          <p>{t("where_should_serve")}{"abc"} </p>
          <ul>
            <li>{checkoutData?.address ? checkoutData?.address : ""}</li>
          </ul>
        </div>
        <div className="card-content ">
          <p>{t("price_details")}</p>
          <ul>
            <li>
              {t("price")}{" "}
              <span>
                {checkoutData?.currency}{" "}
                {Number(
                  checkoutData && checkoutData?.price ? checkoutData?.price : 0
                ).toFixed(2)}
              </span>
            </li>
            {(() => {
              if (checkoutData.rushSlot > 0) {
                return (
                  <li>
                    {t("rush_slot")}{" "}
                    <span>
                      {checkoutData?.currency}{" "}
                      {Number(checkoutData.rushSlot).toFixed(2)}
                    </span>
                  </li>
                );
              }
            })()}

            {(() => {
              if (checkoutData?.isBuyMemberShipRadio === true) {
                return (
                  <li>
                    {t("membership")}{" "}
                    <span>
                      {" "}
                      {checkoutData?.currency}{" "}
                      {countryCurrency?.length > 0
                        ? Number(countryCurrency[0]?.rhPrice)?.toFixed(2)
                        : 0}{" "}
                    </span>
                  </li>
                );
              }
            })()}
            {(() => {
              if (checkoutData?.payment_mode) {
                return (
                  <li>
                    {t("payment_mode")}{" "}
                    <span>
                      {checkoutData?.payment_mode}{" "}
                      {/* {Number(checkoutData?.holidayPrice).toFixed(2)} */}
                    </span>
                  </li>
                );
              }
            })()}
            {/* {(() => {
              if (checkoutData?.vipDiscountPrice > 0) {
                return (
                  <li>
                    {t("vipDiscount")}{" "}
                    <span>
                      {-checkoutData?.vipDiscountPrice}{" "}
                     
                    </span>
                  </li>
                );
              }
            })()} */}

            {(() => {
              if (checkoutData?.CodCharges) {
                return (
                  <li>
                    {t("codCharge")}{" "}
                    <span>
                    {checkoutData?.currency}{" "}
                      {Number(checkoutData?.CodCharges).toFixed(2)}{" "}
                      {/* {Number(checkoutData?.holidayPrice).toFixed(2)} */}
                    </span>
                  </li>
                );
              }
            })()}
            {(() => {
              if (checkoutData?.is_cleaning_materials) {
                return (
                  <li>
                    {t("materialPrice")}{" "}
                    <span>
                    {checkoutData?.currency}{" "}
                      {Number(checkoutData?.totalMaterialPrice).toFixed(2) }{" "}
                      {/* {Number(checkoutData?.holidayPrice).toFixed(2)} */}
                    </span>
                  </li>
                );
              }
            })()}
            {(() => {
              if (checkoutData?.holidayPrice > 0) {
                return (
                  <li>
                    {t("holiday_price")}{" "}
                    <span>
                      {checkoutData?.currency}{" "}
                      {Number(checkoutData?.holidayPrice).toFixed(2)}
                    </span>
                  </li>
                );
              }
            })()}
            {(() => {
              if (checkoutData?.rhClub === true) {
                return (
                  <li>
                    {t("freecredit")}{" "}
                    <span>
                      -{checkoutData?.currency}{" "}
                      {Number(
                        checkoutData && checkoutData?.rh_discount
                          ? checkoutData?.rh_discount
                          : 0
                      ).toFixed(2)}
                    </span>
                  </li>
                );
              }
            })()}
            {(() => {
              if (checkoutData?.isWallet === true) {
                return (
                  <li>
                    {t("wallet_payment")}{" "}
                    <span>
                      -{checkoutData?.currency}{" "}
                      {Number(
                        checkoutData && checkoutData?.wallet_amount
                          ? checkoutData?.wallet_amount
                          : 0
                      ).toFixed(2)}
                    </span>
                  </li>
                );
              }
            })()}
            {(() => {
              if (checkoutData.vatPrice !== 0) {
                return (
                  <li>
                    {t("vat")}{" "}
                    <span>
                      {checkoutData?.currency}{" "}
                      {Number(
                        checkoutData && checkoutData.vatPrice
                          ? checkoutData.vatPrice
                          : 0
                      ).toFixed(2)}
                    </span>
                  </li>
                );
              }
            })()}
            {(() => {
              if (
                checkoutData?.total_discount !== 0 &&
                checkoutData?.page === 5
              ) {
                return (
                  <li>
                    {t("vipDiscount")}{" "}
                    <span>
                      -{checkoutData?.currency}{" "}
                      {Number(
                        (checkoutData && checkoutData?.page === 2) ||
                          checkoutData?.page === 3 ||
                          checkoutData?.page === 4
                          ? checkoutData?.vipDiscountPrice
                            ? checkoutData?.vipDiscountPrice
                            : 0
                          : checkoutData?.vipDiscountPrice
                          ? checkoutData.vipDiscountPrice
                          : 0
                      ).toFixed(2)}
                    </span>
                  </li>
                );
              } else if (
                (checkoutData?.page === 2 ||
                  checkoutData?.page === 3 ||
                  checkoutData?.page === 4) &&
                  Number(checkoutData.discountPrice) !== 0
              ) {
                return (
                 
                  <li>
                    {t("vipDiscount")}{" "}
                    <span>
                      -{checkoutData?.currency}{" "}
                      {Number(
                        (checkoutData && checkoutData?.page === 2) ||
                          checkoutData?.page === 3 ||
                          checkoutData?.page === 4
                          ? checkoutData?.discountPrice
                            ? checkoutData?.discountPrice
                            : 0
                          : checkoutData?.total_discount
                          ? checkoutData.total_discount
                          : 0
                      ).toFixed(2)}
                    </span>
                  </li>
                );
              }
            })()}
            <li>
              {checkoutData?.rhClub === "true" &&
              checkoutData?.rh_discount !== 0 ? (
                <>
                  {t("freecredit")}{" "}
                  <span>
                    -{checkoutData?.currency}{" "}
                    {Number(
                      checkoutData && checkoutData?.rh_discount
                        ? checkoutData?.rh_discount
                        : 0
                    ).toFixed(2)}
                  </span>
                </>
              ) : (
                ""
              )}
            </li>

            <li>
              {checkoutData?.rh_discount== 0 && checkoutData?.promo_code !== "" &&!isCouponPreFilled? (
                <>
                  {checkoutData?.promo_code}{" "}{"("}{checkoutData?.discount_type}{")"}
                  <span>
                    -{checkoutData?.currency}{" "}
                    {Number(
                      checkoutData && checkoutData?.voucherDiscount
                        ? checkoutData?.voucherDiscount
                        : 0
                    ).toFixed(2)}
                  </span>
                </>
              ) : (
                ""
              )}
            </li>

            <li>
              {checkoutData?.total_discount !== 0 ? (
                <>
                  {t("total_discount")}{" "}
                  <span>
                    -{checkoutData?.currency}{" "}
                    {Number(
                      (checkoutData.vipDiscountPrice ? checkoutData.vipDiscountPrice: 0)+
                      (checkoutData.rh_discount ? checkoutData.rh_discount : 
                        checkoutData.promo_code? (checkoutData.voucherDiscount ? checkoutData.voucherDiscount: 0) : 0)
                    ).toFixed(2)}
                  </span>
                </>
              ) : (
                ""
              )}
            </li>

            <li>
              {t("total")}{" "}
              <span>
                <h6>
                  {checkoutData?.currency} {Number(totalPrice)?.toFixed(2)}
                </h6>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default CheckoutSummary;
